import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Fragment } from "react";

export default function MoreButton(props) {
  return (
    <Menu
      as="div"
      className={classNames(
        props.className,
        "relative aspect-square border rounded-lg hover:shadow-md"
      )}
    >
      <Menu.Button
        data-testid={props.buttonID}
        className="h-full w-full flex items-center justify-center p-2 text-gray-500"
      >
        <EllipsisVerticalIcon className="h-5 w-5" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-max origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="">
            {props.options.options.map((option, index) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <button
                    data-testid={props.buttonID + "-" + index}
                    onClick={props.onClick}
                    value={option.value}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      index === 0 && "rounded-t-lg",
                      index + 1 === props.options.options.length &&
                        "rounded-b-lg",
                      "block px-4 py-2 text-sm w-full text-start"
                    )}
                  >
                    {option.text}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
